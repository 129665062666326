<template>
  <v-lazy
    min-height="24px"
    :options="{ 'threshold': 0.1 }"
    transition="fade-transition"
  >
    <div class="d-inline-flex align-center" v-bind="$attrs">
      <div class="mr-2" style="width: 14px; height: 14px" :class="`inline-block bg-${riskProfileColor(riskProfile)}`" />
      <span>{{ $t(`assets.riskProfile.${riskProfile || RiskProfile.UNKNOWN}`) }}</span>
    </div>
  </v-lazy>
</template>

<script setup lang="ts">
import { RiskProfile } from "~~/stores/riskSettings.store"
import { riskProfileColor } from "~~/utils/color"

const props = defineProps<{
  riskProfile?: RiskProfile
}>()
const { riskProfile } = toRefs(props)
</script>